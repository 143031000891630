body {
  margin: 0;
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #FFFFFF;
  background-color: #ffff;
  background-repeat: repeat;
}

a {
  text-decoration: none;
  color: #fff
}

::placeholder {
  color: #014189;
  font-weight: 900;
  font-style: italic;
  text-transform: uppercase;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.BtWhatsapp {
    z-index: 10;
    position: fixed;
    bottom: 20px;
    right: 20px;
    width: 50px;
    height: 50px;
    background-image: url('./Images/bt_whatsapp.png');
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
}

html {
  scroll-behavior: smooth;
}

.TextSchedule {
  font-family: 'gok_stencilregular', sans-serif;
}

h1, h2, h3, h4, h5, h6 {
  margin-block-start: 0em;
  margin-block-end: 0em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
}

.ButtonTeste {
  width: 200px;
  height: 200px
}



img {
  height: auto;
}

a {
  cursor: pointer;
}

button {
  cursor: pointer;
}


.SliderBanner1 {
  width: 100%;
  margin: 0 auto;
  padding-bottom: 0px;
}


.slick-prev {
  left: 50px !important;
  z-index: 100;
  color: #fff !important;
  outline: none !important;
  background: transparent !important;
}

.slick-next {
  right: 50px !important;
}

.SliderProdutos {

  .slick-slide img {
    max-height: 310px;
    margin: 0 auto;
  }

  .slick-prev {
    left: -4vw !important;
    z-index: 100;
    color: #fff !important;
    outline: none !important;
    background: transparent !important;
  }

  .slick-next {
    right: -4vw !important;
  }

  .slick-prev:before,
  .slick-next:before
  {
      opacity: 1 !important;
      color: #014189 !important;
      margin-top: 1px;
  }
}

.SliderClientes {
  .slick-prev {
    left: -4vw !important;
    z-index: 100;
    color: #fff !important;
    outline: none !important;
    background: transparent !important;
  }

  .slick-next {
    right: -4vw !important;
  }

  .slick-prev:before,
  .slick-next:before
  {
      opacity: 1 !important;
      color: #014189 !important;
      margin-top: 1px;
  }
}

.slick-prev:before,
.slick-next:before
{
    opacity: 1 !important;
    color: #fff !important;
    margin-top: 1px;
}

.slick-dots {
  bottom: 20px !important;
}

.slick-dots li.slick-active button:before {
  color: #fff !important;
  opacity: 1 !important;
}

.slick-dots li button:before {
  color: #fff !important;
}

.slick-dots li button:before {
  font-size: 11px !important;
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#Parceiros {
  margin-top: -26vw;
  position: absolute;
}

.IframeCommercial {
  width: 65%;
  height: 29vw;
}

@media (max-width: 1230px) {
  .IframeCommercial {
    width: 57%;
    height: 25vw;
}
}

@media (max-width: 1000px) {
  .IframeCommercial {
    width: 100%;
    height: 43vw;
    margin-top: 35px;
}
}


@media (max-width: 985px) {
  .slick-list {
    margin-right: -14px;
}
}


@media (max-width: 750px) {
  .SliderProdutos {
    .slick-prev {
      left: -10vw !important;
    }
  
    .slick-next {
      right: -10vw !important;
    }
  }
}

@media (max-width: 1220px) {
  .SliderClientes {
    .slick-prev {
      left: -1vw !important;
    }
  
    .slick-next {
      right: -1vw !important;
    }
  }
}

@media (max-width: 550px) {
  .SliderBanner1 .slick-prev {
    left: 18px !important;
  }  
  
  .SliderBanner1 .slick-next {
    right: 18px !important;
  }
  
}

@media (max-width: 500px) {
  .IframeCommercial {
    height: 48vw;
  }
}


.IframeShow {
  border: 0px;
  width: 62%;
}

.IframeShow2Resp {
  display: none;
}

.IframeMercedes {
  height: 420px;
}

.IframeEventos {
  height: 28vw;
}

.IframeEvents {
  height: 62vh;
}

.FormContato {
  display: flex;
  flex-direction: column;
}

.InputForm {
  border: 2px solid #446fe7;
  background-color: transparent;
  margin-top: 15px;
  height: 50px;
  border-radius: 29px;
  padding-left: 4%;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 15px;
}

.InputTextArea {
  border: 2px solid #446fe7;
  background-color: transparent;
  margin-top: 15px;
  height: 188px;
  border-radius: 29px;
  padding-left: 4%;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-size: 15px;
  padding-top: 12px;
}

.BotaoEnvia {
  width: 250px;
  height: 55px;
  background-color: #446fe7;
  border-radius: 29px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'Poppins', sans-serif;
  border: 0px;
  cursor: pointer;
}

.AlertaJs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  height: 205px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #446fe7;
  justify-content: space-around;
  border-radius: 13px;
}

.BotaoAcessarBD {
  background-color: #000;
  width: 180px;
  height: 35px;
  border: 0px;
  border-radius: 12px;
  font-family: 'Poppins';
  font-weight: bold;
  color: #fff;
  font-size: 16px;
  cursor: pointer;
}


::-webkit-scrollbar-track {
  background-color: #323232;
  margin-left: 40px;
  border-radius: 12px;
}
::-webkit-scrollbar {
  width: 9px;
  background: #F4F4F4;
  border-radius: 12px;
}
::-webkit-scrollbar-thumb {
  background: #446fe7;
  border-radius: 12px;
}





